<template>
  <div class="setting">
    <page-nav title="账户设置" />
    <div class="cell-group">
      <van-cell title="编辑资料" size="large" is-link clickable to="/setting/edit" />
      <van-cell title="我的收货地址" size="large" is-link clickable to="/setting/address" />
      <van-cell title="发票模板管理" size="large" is-link clickable to="/setting/invoice" />
      <van-cell title="电子签章" size="large" is-link clickable to="/setting/signature" />
    </div>
    <div class="cell-group">
      <van-button class="exit" block @click="onExit">退出登录</van-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import User from '@/common/user';
import { Cell } from 'vant';
import Fetch from '@/common/fetch';
import { UserLogout } from '@/common/interface';
import PageNav from '../components/PageNav.vue';
Vue.use(Cell);

export default {
  name: 'Setting',
  components: { PageNav },
  data() {
    return {
      userInfo: null,
    };
  },
  methods: {
    onExit() {
      Fetch.post(UserLogout).then(() => {
        this.removeUserInfo();
      }).catch(() => {
        this.removeUserInfo();
      });
    },

    removeUserInfo() {
      User.removeInfo();
      this.$router.replace('/');
    },
  },
}
</script>

<style lang="scss" scoped>
.setting {
  .cell-group {
    background: #fff;
    margin: 20px 0;
    .exit {
      color: red;
    }
  }
}
</style>